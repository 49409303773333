import React from "react";
import { formatStudentName } from "packages/shared/src/EnrollmentApplication";
import { Column, formatDateTime, Grid, Heading, Paragraph, Row, Section } from "@hex-insights/core";
import { MailLink, useActivePageRegistration } from "@hex-insights/router";
import { EnrollmentApplicationModules, StaticURLs } from "@hex-insights/verita.shared";
import { CampusInformation, Logo, Main } from "../../Components";
import { confirmationPageInfo } from "./pageinfo";

export function ConfirmationPage() {
	useActivePageRegistration(confirmationPageInfo);

	const {
		formValues: { students },
	} = EnrollmentApplicationModules.useEnrollmentApplicationFormContext();
	const numStudents = students.length;

	const gridResponsiveColumns = React.useMemo(
		() => ({
			0: 1,
			900: numStudents > 1 ? 2 : 1,
		}),
		[numStudents],
	);

	return (
		<Main style={{ minHeight: 0, position: "relative" }}>
			<Main.Body>
				<Column align="center">
					<Section className="main-content-section">
						<Section.Header>
							<Column justify="spaced-start" align="center">
								<Logo />
								<Heading.H1 noMargin style={{ textAlign: "center" }}>
									Congratulations! Your Application Has Been Submitted!
								</Heading.H1>
							</Column>
						</Section.Header>
						<Section.Body style={{ paddingLeft: 0, paddingRight: 0 }}>
							<Column justify="spaced-start" align="center">
								<Paragraph style={{ fontSize: "1.1rem", fontWeight: "lighter" }}>
									Your Meet & Greet is scheduled for:
								</Paragraph>

								<Grid
									responsiveColumns={gridResponsiveColumns}
									gap="1.5rem"
									style={{ width: "100%", marginBottom: "2rem" }}
								>
									{students.map((student) => (
										<Column
											key={student.formIdentifier}
											justify="spaced-start"
											align="center"
											verticalSpacing="0.5rem"
											style={{ fontSize: "1.1rem" }}
										>
											<strong style={{ fontSize: "1.25rem", whiteSpace: "nowrap" }}>
												{formatStudentName(student)}
											</strong>
											<strong style={{ whiteSpace: "nowrap" }}>
												{formatDateTime(student.interviewMeetingTime ?? new Date(), "dddd, MMM Do, YYYY [at] HH:mm")}
											</strong>
											<span style={{ fontWeight: "lighter" }}>at</span>
											<CampusInformation
												gradeLevel={student.gradeLevelApplyingFor}
												style={{ textAlign: "center", whiteSpace: "nowrap" }}
											/>
										</Column>
									))}
								</Grid>

								<Paragraph
									style={{
										fontSize: "1.1rem",
										fontWeight: "lighter",
										textAlign: "center",
										marginBottom: 0,
									}}
								>
									You will receive an email shortly confirming your application.
								</Paragraph>
								<Paragraph style={{ fontSize: "1.1rem", fontWeight: "lighter", textAlign: "center" }}>
									For now, relax, enjoy, and prepare your student for an amazing learning adventure!
								</Paragraph>

								<Section>
									<Section.Header>
										<Row justify="center">
											<Heading.H3
												noMargin
												style={{
													borderBottom: "3px solid var(--verita-blue)",
												}}
											>
												Contact Us
											</Heading.H3>
										</Row>
									</Section.Header>
									<Section.Body style={{ fontWeight: "lighter" }}>
										<Column justify="spaced-start" align="center" verticalSpacing="0.25rem">
											<span>Verita Early Years Campus</span>
											<span>30 Irakliou St., Glyfada</span>
											<br />
											<span>Verita Primary School Campus</span>
											<span>Smirnis 13, Glyfada</span>
											<br />
											<span>Phone: +30 211 419 9994</span>
											<span>
												Email: <MailLink email="info@veritaschool.gr">info@veritaschool.gr</MailLink>
											</span>
										</Column>
									</Section.Body>
								</Section>
							</Column>
						</Section.Body>
					</Section>
				</Column>

				<div
					style={{
						width: "40%",
						height: "fit-content",
						borderBottomLeftRadius: "var(--enrollment-application__content---border-radius)",
						position: "absolute",
						bottom: 0,
						left: 0,
						overflow: "hidden",
					}}
				>
					<img
						src={StaticURLs.Images.veritaPlantsCropped}
						alt="Verita Plants"
						style={{ width: "100%", height: "auto", display: "block" }}
					/>
				</div>
				<div
					style={{
						width: "40%",
						height: "fit-content",
						position: "absolute",
						borderBottomLeftRadius: "var(--enrollment-application__content---border-radius)",
						bottom: 0,
						right: 0,
						overflow: "hidden",
						transform: "rotateY(0.5turn)",
					}}
				>
					<img
						src={StaticURLs.Images.veritaPlantsCropped}
						alt="Verita Plants"
						style={{ width: "100%", height: "auto", display: "block" }}
					/>
				</div>
			</Main.Body>
		</Main>
	);
}
