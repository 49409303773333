import React from "react";
import { Buffer } from "buffer/";
import ReactDOM from "react-dom/client";
import { ApolloProvider } from "@apollo/client";
import { Models, Navigation } from "@hex-insights/app-modules";
import {
	EscapeListenerManager,
	GlobalStackingManager,
	LocalStorageManager,
	NotificationManager,
	ScrollLockManager,
} from "@hex-insights/core";
import { PermissionManager } from "@hex-insights/permissioning";
import { ActivePageManager, BrowserRouter, QueryStateManager, ScrollManager } from "@hex-insights/router";
import "@hex-insights/core/dist/index.css";
import "@hex-insights/router/dist/index.css";
import "@hex-insights/forms/dist/index.css";
import "@hex-insights/tables/dist/index.css";
import "@hex-insights/app-modules/dist/index.css";
import "@hex-insights/verita.shared/index.css";
import { client } from "./apollo";
import { App } from "./App";
import { GlobalErrorBoundary, VersionController } from "./Components";
import { hubDetailPages } from "./hub-detail-pages";
import { configureLogging } from "./logging";
import { rootPageInfo } from "./root.pageinfo";
import "./theme.css";

export function Root() {
	return (
		<GlobalErrorBoundary>
			<GlobalStackingManager>
				<ApolloProvider client={client}>
					<LocalStorageManager>
						<BrowserRouter>
							<ScrollManager>
								<ScrollLockManager>
									<EscapeListenerManager>
										<QueryStateManager>
											<NotificationManager>
												<Models.HubDetailPagesManager pages={hubDetailPages}>
													<ActivePageManager rootPage={rootPageInfo}>
														<PermissionManager>
															<App />
															<Navigation.ScrollToTopButton />
															<VersionController />
														</PermissionManager>
													</ActivePageManager>
												</Models.HubDetailPagesManager>
											</NotificationManager>
										</QueryStateManager>
									</EscapeListenerManager>
								</ScrollLockManager>
							</ScrollManager>
						</BrowserRouter>
					</LocalStorageManager>
				</ApolloProvider>
			</GlobalStackingManager>
		</GlobalErrorBoundary>
	);
}

global.Buffer = global.Buffer ?? Buffer;
configureLogging();

const root = ReactDOM.createRoot(document.querySelector("#root")!);
root.render(<Root />);
