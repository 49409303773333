import { Column, Conditional, Else, Grid, Heading, HeadingProps, If, Paragraph, Row } from "@hex-insights/core";
import { ButtonLink, EnrollmentApplicationModules } from "@hex-insights/verita.shared";
import { studentsCreatePageInfo } from "../../Pages/Students/CreatePage/pageinfo";
import { StudentCard } from "../StudentCard";

const responsiveGridColumns = {
	0: 1,
	600: 2,
};

export function StudentsIndex() {
	const {
		formValues: { students },
	} = EnrollmentApplicationModules.useEnrollmentApplicationFormContext();

	return (
		<Conditional>
			<If condition={students.length === 0}>
				<Column justify="spaced-start">
					<Paragraph>You haven't added a student to this application yet.</Paragraph>

					<Row justify="center">
						<ButtonLink variant="primary" to={studentsCreatePageInfo.to}>
							Add Student
						</ButtonLink>
					</Row>
				</Column>
			</If>
			<Else>
				<Grid responsiveColumns={responsiveGridColumns} gap="1rem">
					{students.map((e) => (
						<StudentCard key={e.formIdentifier} student={e} />
					))}
				</Grid>
			</Else>
		</Conditional>
	);
}

StudentsIndex.Heading = function (props: Omit<HeadingProps, "children">) {
	const {
		formValues: { students },
	} = EnrollmentApplicationModules.useEnrollmentApplicationFormContext();

	// Don't use toGrammaticalNumber so that zero shows as "Student" as this is the most common case (single student)
	return <Heading {...props}>Your {students.length <= 1 ? "Student" : "Students"}</Heading>;
};
