import React from "react";
import { Column, Heading, Paragraph, pickKeys, Row, Section, toLocalDateString } from "@hex-insights/core";
import {
	Form,
	FormSubmitFunction,
	SubmissionStatus,
	SubmitButton,
	useFormState,
	ValidationDisplayPolicy,
} from "@hex-insights/forms";
import { useActivePageRegistration, useHistory } from "@hex-insights/router";
import { ButtonLink, EnrollmentApplicationModules } from "@hex-insights/verita.shared";
import { Main } from "../../Components";
import { confirmationPageInfo } from "../Confirmation/pageinfo";
import { reviewPageInfo } from "../Review/pageinfo";
import { submissionPageInfo } from "./pageinfo";
import styles from "./styles.module.css";

type SubmissionFormValues = EnrollmentApplicationModules.EnrollmentApplicationFormValues.DataConsentFormValues &
	EnrollmentApplicationModules.EnrollmentApplicationFormValues.SignatureFormValues;

const initialSubmissionFormValues: SubmissionFormValues = {
	...EnrollmentApplicationModules.EnrollmentApplicationFormValues.initialDataConsentFormValues,
	...EnrollmentApplicationModules.EnrollmentApplicationFormValues.initialSignatureFormValues,
};

export function SubmissionPage() {
	useActivePageRegistration(submissionPageInfo);

	const formState = useFormState({
		initialFormValues: React.useMemo<SubmissionFormValues>(
			() => ({ ...initialSubmissionFormValues, signatureDate: toLocalDateString(new Date()) }),
			[],
		),
		validationDisplayPolicy: ValidationDisplayPolicy.failureOnly,
	});

	const {
		formValues: fullFormValues,
		setFormValues: setFullFormValues,
		submit,
	} = EnrollmentApplicationModules.useEnrollmentApplicationFormContext();
	const onSubmit = React.useCallback<FormSubmitFunction<SubmissionFormValues>>(
		async ({ formValues }) => {
			setFullFormValues((prev) => ({
				...prev,
				dataConsent: pickKeys(formValues, ["doesConsentToDataUse"]),
				signature: pickKeys(formValues, ["signatureName", "signatureDate"]),
			}));
			return submit({
				...fullFormValues,
				dataConsent: pickKeys(formValues, ["doesConsentToDataUse"]),
				signature: pickKeys(formValues, ["signatureName", "signatureDate"]),
			});
		},
		[setFullFormValues, fullFormValues, submit],
	);

	const { push: pushHistory } = useHistory();
	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			pushHistory(confirmationPageInfo.to);
		}
	}, [formState.submissionStatus, pushHistory]);

	const footerButtonSize = EnrollmentApplicationModules.NavigationFooter.useResponsiveButtonSize();

	return (
		<Main>
			<Main.Body>
				<Column align="center">
					<Section className="main-content-section">
						<Section.Header>
							<Heading.H2 noMargin>Declarations</Heading.H2>
						</Section.Header>
						<Section.Body>
							<Column align="center">
								<Form
									formState={formState}
									onSubmit={formState.onSubmitWrapper(onSubmit)}
									noNavigationPrompt
									SubmissionSuccessNotification={() => <div></div>}
								>
									<Paragraph>
										In our effort to safeguard the personal data of our families and especially students enrolled in the
										academic programs at Verita International School, we hereby seek parental or legal guardian consent
										for the collection and processing of personal data.
									</Paragraph>

									<Paragraph>
										Student and parent personal data will be used to complete the enrollment and admission process of
										the student, while student's data will also be used to document and assess the student's
										participation in the accredited academic programs co-curricular and extra-curricular activities at
										Verita International School. Personal data collected will be restricted to a minimum and will remain
										in possession of Verita International School for as long as the student is enrolled or for ten years
										after graduating from the school. After that, all collected data will be deleted from all electronic
										or physical storage systems.
									</Paragraph>

									<Paragraph>
										Please be advised that Verita International School has taken all necessary organizational and
										technical measures to safeguard your child's data in its possession.
									</Paragraph>

									<div className={styles["data-consent-fields"]} style={{ margin: "var(--paragraph---margin)" }}>
										<EnrollmentApplicationModules.DataConsentForm.Fields
											formState={formState}
											style={{ width: "100%" }}
										/>
									</div>

									<Paragraph>
										I/We confirm that all the information provided by me/us is correct. I/We further agree to inform the
										school promptly, in writing, of any subsequent changes or updates. I/We understand that any
										incorrect information given by me/us will prevent Verita School from providing the best care for our
										child and will not hold the school responsible for any consequence due to inaccurate information
										I/we have provided.
									</Paragraph>

									<Section>
										<Section.Header>
											<Heading.H3 noMargin>Electronic Signature</Heading.H3>
										</Section.Header>
										<Section.Body>
											<EnrollmentApplicationModules.SignatureForm.Fields formState={formState} />
										</Section.Body>
									</Section>
								</Form>
							</Column>
						</Section.Body>
					</Section>
				</Column>
			</Main.Body>
			<Main.Footer>
				<EnrollmentApplicationModules.NavigationFooter>
					<Row justify="space-between" align="center">
						<ButtonLink variant="secondary" size={footerButtonSize} to={reviewPageInfo.to}>
							Back
						</ButtonLink>
						<SubmitButton
							size={footerButtonSize}
							submissionStatus={formState.submissionStatus}
							onClick={formState.onSubmitWrapper(onSubmit)}
						>
							Submit Application
						</SubmitButton>
					</Row>
				</EnrollmentApplicationModules.NavigationFooter>
			</Main.Footer>
		</Main>
	);
}
