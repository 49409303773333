import React from "react";
import { asString, ChildrenProps } from "@hex-insights/core";
import { useQueryStateValuesOnMount } from "@hex-insights/router";
import { EnrollmentApplicationModules } from "@hex-insights/verita.shared";

const metaDataQueryTypeFilterObject = {
	source: asString,
};

const defaultMetaDataQueryValues = {
	source: "",
};

export type MetaDataManagerProps = Partial<ChildrenProps>;

export function MetaDataManager({ children }: MetaDataManagerProps) {
	const { source } = useQueryStateValuesOnMount(metaDataQueryTypeFilterObject, defaultMetaDataQueryValues);
	const { setFormValues } = EnrollmentApplicationModules.useEnrollmentApplicationFormContext();
	React.useEffect(() => {
		setFormValues((prev) => ({
			...prev,
			metaData: { source },
		}));
	}, [source, setFormValues]);

	return <React.Fragment>{children}</React.Fragment>;
}
