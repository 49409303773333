import { ClassNameProps, Column, StyleProps } from "@hex-insights/core";
import {
	EnrollmentApplicationStudentFormValues,
	EnrollmentApplicationStudentGradeLevelApplyingFor,
} from "@hex-insights/verita.shared";

export type CampusInformationProps = {
	gradeLevel: EnrollmentApplicationStudentFormValues.EnrollmentApplicationCreate["gradeLevelApplyingFor"];
} & Partial<ClassNameProps & StyleProps>;

export function CampusInformation({ gradeLevel, className, style }: CampusInformationProps) {
	switch (gradeLevel) {
		case EnrollmentApplicationStudentGradeLevelApplyingFor.Nursery:
		case EnrollmentApplicationStudentGradeLevelApplyingFor.Reception:
			return (
				<Column align="center" className={className} style={style}>
					<strong>Verita Early Years Campus</strong>
					<strong>30 Irakliou St., Glyfada</strong>
				</Column>
			);
		default:
			return (
				<Column align="center" className={className} style={style}>
					<strong>Verita Primary School Campus</strong>
					<strong>Smirnis 13, Glyfada</strong>
				</Column>
			);
	}
}
