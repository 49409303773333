import { newPageInfo } from "@hex-insights/router";
import { studentsPageInfo } from "../pageinfo";

export const studentsCreatePageInfo = newPageInfo({
	title: "New Student",
	description: "Add a student to the application.",
	exact: true,
	to: studentsPageInfo.to + "/new",
	path: studentsPageInfo.path + "/new",
	parent: studentsPageInfo,
});
