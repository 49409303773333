import { newPageInfo } from "@hex-insights/router";
import { parentsPageInfo } from "../pageinfo";

export const parentsIndexPageInfo = newPageInfo({
	name: "Parents/Guardians Index Page",
	title: "",
	description: "Overview of parents and guardians on the application.",
	exact: true,
	to: parentsPageInfo.to,
	path: parentsPageInfo.path,
	parent: parentsPageInfo,
});
