import { Column, Conditional, Else, If, Row, Section } from "@hex-insights/core";
import { useActivePageRegistration } from "@hex-insights/router";
import { ButtonLink, EnrollmentApplicationModules } from "@hex-insights/verita.shared";
import { Main, ParentsIndex } from "../../../Components";
import { reviewPageInfo } from "../../Review/pageinfo";
import { parentsCreatePageInfo } from "../CreatePage/pageinfo";
import { parentsIndexPageInfo } from "./pageinfo";

export function ParentsIndexPage() {
	useActivePageRegistration(parentsIndexPageInfo);

	const {
		formValues: { parents },
	} = EnrollmentApplicationModules.useEnrollmentApplicationFormContext();
	const hasParents = parents.length > 0;

	const footerButtonSize = EnrollmentApplicationModules.NavigationFooter.useResponsiveButtonSize();

	return (
		<Main>
			<Main.Body>
				<Column align="center">
					<Section className="main-content-section">
						<Section.Header>
							<ParentsIndex.Heading level={2} noMargin />
						</Section.Header>
						<Section.Body>
							<ParentsIndex />
						</Section.Body>
					</Section>
				</Column>
			</Main.Body>
			<Main.Footer>
				<EnrollmentApplicationModules.NavigationFooter>
					<Row justify="spaced-end" align="center">
						<Conditional>
							<If condition={hasParents}>
								<ButtonLink variant="secondary" size={footerButtonSize} to={parentsCreatePageInfo.to}>
									Add Another Parent/Guardian
								</ButtonLink>
								<ButtonLink variant="primary" size={footerButtonSize} to={reviewPageInfo.to}>
									Continue to Review
								</ButtonLink>
							</If>
							<Else>
								<ButtonLink variant="primary" size={footerButtonSize} to={parentsCreatePageInfo.to}>
									Add Parent/Guardian
								</ButtonLink>
							</Else>
						</Conditional>
					</Row>
				</EnrollmentApplicationModules.NavigationFooter>
			</Main.Footer>
		</Main>
	);
}
