import React from "react";
import { Button, Column, Heading, HeadingLevel, If, Row, Section } from "@hex-insights/core";
import {
	anyFieldChanged,
	EditMode,
	Form,
	FormSubmitFunction,
	FormType,
	SubmissionStatus,
	submissionSuccess,
	SubmitButton,
	useFormState,
	useInternalField,
	useOnSuccess,
	ValidationDisplayPolicy,
} from "@hex-insights/forms";
import { ButtonLink, EnrollmentApplicationStudentForm, FormActionRow, FormColumn, Tile } from "../../../../Components";
import { EnrollmentApplicationStudentIcon } from "../../../../Components/EnrollmentApplicationStudentIcon";
import { EnrollmentApplicationStudentFormValues } from "../../../../Utilities";
import {
	EnrollmentApplicationFormValues,
	formatStudentNameShort,
	useEnrollmentApplicationFormContext,
} from "../../../Utilities";
import { NavigationFooter } from "../../NavigationFooter";
import styles from "./styles.module.css";

export type UseCreateFormArgs = {
	onSuccess: () => void;
};

export function useBasicCreateForm({
	onSuccess,
	initialFormValues,
}: UseCreateFormArgs & {
	initialFormValues?: Partial<EnrollmentApplicationStudentFormValues.EnrollmentApplicationCreateBasic>;
}) {
	const formState = useFormState({
		initialFormValues: React.useMemo(
			() => ({
				...EnrollmentApplicationStudentFormValues.initialEnrollmentApplicationCreateBasic,
				...initialFormValues,
			}),
			[initialFormValues],
		),
		validationDisplayPolicy: ValidationDisplayPolicy.failureOnly,
		formType: FormType.Create,
	});

	useOnSuccess(formState, onSuccess);

	return { formState };
}

export function usePersonalityCreateForm({
	onSuccess,
	initialFormValues,
}: UseCreateFormArgs & {
	initialFormValues?: Partial<EnrollmentApplicationStudentFormValues.EnrollmentApplicationCreatePersonality>;
}) {
	const formState = useFormState({
		initialFormValues: React.useMemo(
			() => ({
				...EnrollmentApplicationStudentFormValues.initialEnrollmentApplicationCreatePersonality,
				...initialFormValues,
			}),
			[initialFormValues],
		),
		validationDisplayPolicy: ValidationDisplayPolicy.failureOnly,
		formType: FormType.Create,
	});

	useOnSuccess(formState, onSuccess);

	return { formState };
}

export function useInterviewCreateForm({
	onSuccess,
	initialFormValues,
}: UseCreateFormArgs & {
	initialFormValues?: Partial<EnrollmentApplicationStudentFormValues.EnrollmentApplicationCreateInterview>;
}) {
	const formState = useFormState({
		initialFormValues: React.useMemo(
			() => ({
				...EnrollmentApplicationStudentFormValues.initialEnrollmentApplicationCreateInterview,
				...initialFormValues,
			}),
			[initialFormValues],
		),
		validationDisplayPolicy: ValidationDisplayPolicy.failureOnly,
		formType: FormType.Create,
	});

	useOnSuccess(formState, onSuccess);

	return { formState };
}

export type ControlledCreateProps = {
	onSuccess: () => void;
	onCancel?: () => void;
};

export function ControlledCreate({ onSuccess, onCancel }: ControlledCreateProps) {
	const formState = useFormState({
		initialFormValues: EnrollmentApplicationFormValues.initialStudentFormValues,
		validationDisplayPolicy: ValidationDisplayPolicy.failureOnly,
		formType: FormType.Create,
	});
	EnrollmentApplicationFormValues.useFormIdentifier(formState);

	const { setFormValues } = useEnrollmentApplicationFormContext();
	const onSubmit = React.useCallback<FormSubmitFunction<EnrollmentApplicationFormValues.StudentFormValues>>(
		async ({ formValues }) => {
			setFormValues((prev) => ({ ...prev, students: [...prev.students, formValues] }));
			return submissionSuccess();
		},
		[setFormValues],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	return (
		<Form
			formState={formState}
			onSubmit={formState.onSubmitWrapper(onSubmit)}
			noNavigationPrompt
			noNotifications
			noChangesDialog
		>
			<Column justify="spaced-start" verticalSpacing="5rem">
				<Column id="basic" className={styles["form-section-container--create"] + " " + "scroll-target"}>
					<Column justify="center" style={{ flexGrow: 1 }}>
						<Tile>
							<Tile.Header>
								<Heading level={2} noMargin>
									Let's Start With Some Simple Information About Your Student
								</Heading>
							</Tile.Header>
							<Tile.Body className={styles["form-section__body"]}>
								<Row justify="center">
									<Column justify="spaced-start" className={styles["form-section__column"]}>
										<EnrollmentApplicationStudentForm.BasicCreateFields formState={formState} headingLevel={3} />
									</Column>
								</Row>
							</Tile.Body>
						</Tile>
					</Column>

					<NavigationFooter className={styles["form-section__navigation-footer"]}>
						<Row justify={!!onCancel ? "space-between" : "flex-end"} align="center">
							{!!onCancel && (
								<Button variant="secondary" onClick={onCancel}>
									Cancel
								</Button>
							)}

							<ButtonLink variant="primary" to="#personality">
								Next Section
							</ButtonLink>
						</Row>
					</NavigationFooter>
				</Column>

				<Column id="personality" className={styles["form-section-container--create"] + " " + "scroll-target"}>
					<Column justify="center" style={{ flexGrow: 1 }}>
						<Tile>
							<Tile.Header>
								<Row justify="spaced-start" align="center">
									<EnrollmentApplicationStudentIcon image={formState.formValues.image} />
									<Heading level={2} noMargin>
										Let's Get to Know {formatStudentNameShort(formState.formValues)}!
									</Heading>
								</Row>
							</Tile.Header>
							<Tile.Body className={styles["form-section__body"]}>
								<Column
									justify="spaced-start"
									className={styles["form-section__column"] + " " + styles["personality-fields"]}
								>
									<EnrollmentApplicationStudentForm.PersonalityFields formState={formState} />
								</Column>
							</Tile.Body>
						</Tile>
					</Column>

					<NavigationFooter className={styles["form-section__navigation-footer"]}>
						<Row justify="space-between" align="center">
							<ButtonLink variant="secondary" to="#basic">
								Back
							</ButtonLink>
							<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
								Continue
							</SubmitButton>
						</Row>
					</NavigationFooter>
				</Column>
			</Column>
		</Form>
	);
}

export type ControlledDetailProps = {
	initialFormValues: EnrollmentApplicationFormValues.StudentFormValues;
	onSuccess: () => void;
	setHasChanges?: (action: React.SetStateAction<boolean>) => void;
	headingLevel?: HeadingLevel;
};

export function ControlledDetail({
	initialFormValues,
	onSuccess,
	setHasChanges,
	headingLevel = 2,
}: ControlledDetailProps) {
	const formState = useFormState({
		initialFormValues: initialFormValues,
		validationDisplayPolicy: ValidationDisplayPolicy.failureOnly,
		formType: FormType.Update,
		editMode: EditMode.WriteOnly,
	});
	useInternalField(formState, "formIdentifier");

	const hasChanges = anyFieldChanged(formState);
	React.useEffect(() => {
		if (setHasChanges) {
			setHasChanges(hasChanges);
		}
	}, [setHasChanges, hasChanges]);

	const { setFormValues } = useEnrollmentApplicationFormContext();
	const onSubmit = React.useCallback<FormSubmitFunction<EnrollmentApplicationFormValues.StudentFormValues>>(
		async ({ formValues }) => {
			setFormValues((prev) => ({
				...prev,
				students: prev.students.map((e) => (e.formIdentifier === formValues.formIdentifier ? formValues : e)),
			}));
			return submissionSuccess();
		},
		[setFormValues],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	return (
		<Form
			formState={formState}
			onSubmit={formState.onSubmitWrapper(onSubmit)}
			noNavigationPrompt
			noNotifications
			noChangesDialog
		>
			<FormColumn>
				<Section>
					<Section.Header>
						<Heading level={headingLevel} noMargin>
							Basic Information
						</Heading>
					</Section.Header>
					<Section.Body className={styles["form-section__body"]}>
						<Column justify="spaced-start" className={styles["form-section__column"]}>
							<EnrollmentApplicationStudentForm.BasicCreateFields formState={formState} />
						</Column>
					</Section.Body>
				</Section>

				<Section>
					<Section.Header>
						<Heading level={headingLevel} noMargin>
							Personality
						</Heading>
					</Section.Header>
					<Section.Body className={styles["form-section__body"]}>
						<Column
							justify="spaced-start"
							className={styles["form-section__column"] + " " + styles["personality-fields"]}
						>
							<EnrollmentApplicationStudentForm.PersonalityFields formState={formState} />
						</Column>
					</Section.Body>
				</Section>

				<Section>
					<Section.Header>
						<Heading level={headingLevel} noMargin>
							Meet &amp; Greet Time
						</Heading>
					</Section.Header>
					<Section.Body className={styles["form-section__body"]}>
						<Column justify="spaced-start" className={styles["form-section__column"]}>
							<EnrollmentApplicationStudentForm.InterviewFields
								formState={formState}
								gradeLevelApplyingFor={formState.formValues.gradeLevelApplyingFor}
							/>
						</Column>
					</Section.Body>
				</Section>

				<If condition={hasChanges}>
					<FormActionRow>
						<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
							Update
						</SubmitButton>
					</FormActionRow>
				</If>
			</FormColumn>
		</Form>
	);
}
