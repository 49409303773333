import { Redirect, Route } from "@hex-insights/router";
import { EnrollmentApplicationModules } from "@hex-insights/verita.shared";
import { Footer, Header, PageRouteSwitch } from "./Components";
import { MetaDataManager } from "./Components/MetaDataManager";
import { BillingSetupPage } from "./Pages/BillingSetup";
import { billingSetupPageInfo } from "./Pages/BillingSetup/pageinfo";
import { ConfirmationPage } from "./Pages/Confirmation";
import { confirmationPageInfo } from "./Pages/Confirmation/pageinfo";
import { HomePage } from "./Pages/Home";
import { homePageInfo } from "./Pages/Home/pageinfo";
import { LandingPage } from "./Pages/Landing";
import { landingPageInfo } from "./Pages/Landing/pageinfo";
import { NonEnrollmentConfirmationPage } from "./Pages/NonEnrollmentConfirmation";
import { nonEnrollmentConfirmationPageInfo } from "./Pages/NonEnrollmentConfirmation/pageinfo";
import { ParentsPage } from "./Pages/Parents";
import { parentsPageInfo } from "./Pages/Parents/pageinfo";
import { ReviewPage } from "./Pages/Review";
import { reviewPageInfo } from "./Pages/Review/pageinfo";
import { StudentsPage } from "./Pages/Students";
import { studentsPageInfo } from "./Pages/Students/pageinfo";
import { SubmissionPage } from "./Pages/Submission";
import { submissionPageInfo } from "./Pages/Submission/pageinfo";
import styles from "./App.module.css";

export function App() {
	return (
		<EnrollmentApplicationModules.EnrollmentApplicationFormContextManager>
			<MetaDataManager>
				<PageRouteSwitch>
					<Route {...landingPageInfo.routeProps}>
						<LandingPage />
					</Route>
					<Route {...confirmationPageInfo.routeProps}>
						<div className={styles["content"]}>
							<ConfirmationPage />
						</div>
					</Route>
					<Route {...billingSetupPageInfo.routeProps}>
						<div className={styles["content"]}>
							<BillingSetupPage />
						</div>
					</Route>
					<Route exact path="/enrollment-confirmation">
						<Redirect to={billingSetupPageInfo.to} />
					</Route>
					<Route {...nonEnrollmentConfirmationPageInfo.routeProps}>
						<div className={styles["content"]}>
							<NonEnrollmentConfirmationPage />
						</div>
					</Route>
					<Route>
						<div className={styles["content"]}>
							<Header />
							<PageRouteSwitch>
								<Route {...homePageInfo.routeProps}>
									<HomePage />
								</Route>
								<Route {...studentsPageInfo.routeProps}>
									<StudentsPage />
								</Route>
								<Route {...parentsPageInfo.routeProps}>
									<ParentsPage />
								</Route>
								<Route {...reviewPageInfo.routeProps}>
									<ReviewPage />
								</Route>
								<Route {...submissionPageInfo.routeProps}>
									<SubmissionPage />
								</Route>
							</PageRouteSwitch>
						</div>
						<Footer />
					</Route>
				</PageRouteSwitch>
			</MetaDataManager>
		</EnrollmentApplicationModules.EnrollmentApplicationFormContextManager>
	);
}
