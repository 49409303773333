import { Heading, Row, Section } from "@hex-insights/core";
import { useActivePageRegistration } from "@hex-insights/router";
import { ButtonLink, EnrollmentApplicationModules } from "@hex-insights/verita.shared";
import { Main, ParentsIndex, StudentsIndex } from "../../Components";
import { reviewPageInfo } from "../Review/pageinfo";
import { homePageInfo } from "./pageinfo";

export function HomePage() {
	useActivePageRegistration(homePageInfo);

	const footerButtonSize = EnrollmentApplicationModules.NavigationFooter.useResponsiveButtonSize();

	return (
		<Main>
			<Main.Body>
				<Heading level={1}>Home</Heading>

				<Section>
					<Section.Header>
						<StudentsIndex.Heading level={2} noMargin />
					</Section.Header>
					<Section.Body>
						<StudentsIndex />
					</Section.Body>
				</Section>

				<Section>
					<Section.Header>
						<ParentsIndex.Heading level={2} noMargin />
					</Section.Header>
					<Section.Body>
						<ParentsIndex />
					</Section.Body>
				</Section>
			</Main.Body>
			<Main.Footer>
				<EnrollmentApplicationModules.NavigationFooter>
					<Row justify="flex-end" align="center">
						<ButtonLink variant="primary" size={footerButtonSize} to={reviewPageInfo.to}>
							Review Application for Submission
						</ButtonLink>
					</Row>
				</EnrollmentApplicationModules.NavigationFooter>
			</Main.Footer>
		</Main>
	);
}
