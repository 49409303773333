import { newPageInfo } from "@hex-insights/router";
import { rootPageInfo } from "../../root.pageinfo";

export const confirmationPageInfo = newPageInfo({
	title: "Confirmation",
	description: "We've received your application.",
	exact: true,
	to: "/confirmation",
	path: "/confirmation",
	parent: rootPageInfo,
});
