import * as EnrollmentApplicationFormValues from "./form";

export function formatStudentNameShort({
	firstName,
	nickname,
}: Pick<EnrollmentApplicationFormValues.StudentFormValues, "firstName" | "nickname">) {
	if (nickname) {
		return nickname;
	}
	if (firstName) {
		return firstName;
	}
	return "Your Student";
}

export function formatStudentName({
	firstName,
	nickname,
	lastName,
}: Pick<EnrollmentApplicationFormValues.StudentFormValues, "firstName" | "nickname" | "lastName">) {
	if (nickname) {
		return `${firstName} (${nickname}) ${lastName}`;
	}
	return `${firstName} ${lastName}`;
}
