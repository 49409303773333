import React from "react";
import {
	Button,
	Column,
	formatDateTime,
	getUUID,
	Heading,
	If,
	List,
	Paragraph,
	Row,
	scrollToTop,
	Section,
	useCount,
} from "@hex-insights/core";
import { Form, FormSubmitFunction, submissionSuccess, SubmitButton } from "@hex-insights/forms";
import { useActivePageRegistration, useHistory } from "@hex-insights/router";
import {
	EnrollmentApplicationModules,
	EnrollmentApplicationStudentForm,
	EnrollmentApplicationStudentFormat,
	EnrollmentApplicationStudentFormValues,
	EnrollmentApplicationStudentGradeLevelApplyingFor,
	EnrollmentApplicationStudentIcon,
} from "@hex-insights/verita.shared";
import { CampusInformation, Main } from "../../../Components";
import { studentsIndexPageInfo } from "../IndexPage/pageinfo";
import { studentsCreatePageInfo } from "./pageinfo";
import styles from "./styles.module.css";

export function StudentsCreatePage() {
	useActivePageRegistration(studentsCreatePageInfo);

	const { count: pageIndex, increment: incrementPageIndex, decrement: decrementPageIndex } = useCount(0);
	React.useEffect(() => {
		scrollToTop();
	}, [pageIndex]);

	const [basicFormValues, setBasicFormValues] = React.useState(
		EnrollmentApplicationStudentFormValues.initialEnrollmentApplicationCreateBasic,
	);
	const onSubmitBasic = React.useCallback<
		FormSubmitFunction<EnrollmentApplicationStudentFormValues.EnrollmentApplicationCreateBasic>
	>(async ({ formValues }) => {
		setBasicFormValues(formValues);
		return submissionSuccess();
	}, []);

	const [personalityFormValues, setPersonalityFormValues] = React.useState(
		EnrollmentApplicationStudentFormValues.initialEnrollmentApplicationCreatePersonality,
	);
	const onBackPersonality = React.useCallback(
		(formValues: EnrollmentApplicationStudentFormValues.EnrollmentApplicationCreatePersonality) => {
			setPersonalityFormValues(formValues);
			decrementPageIndex();
		},
		[decrementPageIndex],
	);
	const onSubmitPersonality = React.useCallback<
		FormSubmitFunction<EnrollmentApplicationStudentFormValues.EnrollmentApplicationCreatePersonality>
	>(async ({ formValues }) => {
		setPersonalityFormValues(formValues);
		return submissionSuccess();
	}, []);

	const [interviewFormValues, setInterviewFormValues] = React.useState(
		EnrollmentApplicationStudentFormValues.initialEnrollmentApplicationCreateInterview,
	);
	const onBackInterview = React.useCallback(
		(formValues: EnrollmentApplicationStudentFormValues.EnrollmentApplicationCreateInterview) => {
			setInterviewFormValues(formValues);
			decrementPageIndex();
		},
		[decrementPageIndex],
	);

	const { setFormValues } = EnrollmentApplicationModules.useEnrollmentApplicationFormContext();
	const onSubmit = React.useCallback<
		FormSubmitFunction<EnrollmentApplicationStudentFormValues.EnrollmentApplicationCreateInterview>
	>(
		async ({ formValues }) => {
			setFormValues((prev) => ({
				...prev,
				students: [
					...prev.students,
					{ formIdentifier: getUUID(), ...basicFormValues, ...personalityFormValues, ...formValues },
				],
			}));
			return submissionSuccess();
		},
		[setFormValues, basicFormValues, personalityFormValues],
	);

	const { push: pushHistory } = useHistory();
	const onSuccess = React.useCallback(() => {
		pushHistory(studentsIndexPageInfo.to);
	}, [pushHistory]);

	const {
		formValues: { students: otherStudents },
	} = EnrollmentApplicationModules.useEnrollmentApplicationFormContext();
	const hasOtherStudents = otherStudents.length > 0;
	const onCancel = React.useCallback(() => {
		pushHistory(studentsIndexPageInfo.to);
	}, [pushHistory]);

	if (pageIndex <= 0) {
		return (
			<BasicForm
				initialFormValues={basicFormValues}
				hasOtherStudents={hasOtherStudents}
				onCancel={hasOtherStudents ? onCancel : undefined}
				onSubmit={onSubmitBasic}
				onSuccess={incrementPageIndex}
			/>
		);
	}

	if (pageIndex === 1) {
		return (
			<PersonalityForm
				basicFormValues={basicFormValues}
				initialFormValues={personalityFormValues}
				onBack={onBackPersonality}
				onSubmit={onSubmitPersonality}
				onSuccess={incrementPageIndex}
			/>
		);
	}

	return (
		<InterviewForm
			basicFormValues={basicFormValues}
			initialFormValues={interviewFormValues}
			onBack={onBackInterview}
			onSubmit={onSubmit}
			onSuccess={onSuccess}
		/>
	);
}

type BasicFormProps = {
	initialFormValues: EnrollmentApplicationStudentFormValues.EnrollmentApplicationCreateBasic;
	hasOtherStudents: boolean;
	onCancel?: () => void;
	onSubmit: FormSubmitFunction<EnrollmentApplicationStudentFormValues.EnrollmentApplicationCreateBasic>;
	onSuccess: () => void;
};

function BasicForm({ initialFormValues, hasOtherStudents, onCancel, onSubmit, onSuccess }: BasicFormProps) {
	const { formState } = EnrollmentApplicationModules.StudentForm.useBasicCreateForm({ onSuccess, initialFormValues });

	const footerButtonSize = EnrollmentApplicationModules.NavigationFooter.useResponsiveButtonSize();

	return (
		<Main>
			<Main.Body>
				<Column justify="spaced-start" align="center">
					<If condition={!hasOtherStudents}>
						<Section className="main-content-section">
							<Section.Body>
								<Paragraph
									style={{
										fontSize: "0.9rem",
										color: "#777",
										textAlign: "center",
									}}
								>
									Verita International Schools is committed to providing equal access to educational opportunities
									regardless of race, color, religion, national origin, disability, sex, gender identity, or sexual
									orientation. Our policies, procedures, and safer recruitment ensure students are safeguarded from any
									forms of neglect, physical, sexual, or emotional harm.
								</Paragraph>
							</Section.Body>
						</Section>
					</If>

					<Section className="main-content-section">
						<Section.Header>
							<Heading.H2 noMargin>Let's Start With Some Simple Information About Your Student</Heading.H2>
						</Section.Header>
						<Section.Body>
							<Row justify="center">
								<Form
									formState={formState}
									onSubmit={formState.onSubmitWrapper(onSubmit)}
									noNotifications
									noNavigationPrompt
									noChangesDialog
									noCloseConfirmation
								>
									<Column justify="spaced-start" className={styles["form-section__column"]}>
										<EnrollmentApplicationStudentForm.BasicCreateFields formState={formState} headingLevel={3} />
									</Column>
								</Form>
							</Row>
						</Section.Body>
					</Section>
				</Column>
			</Main.Body>
			<Main.Footer>
				<EnrollmentApplicationModules.NavigationFooter>
					<Row justify={!!onCancel ? "space-between" : "flex-end"} align="center">
						{!!onCancel && (
							<Button variant="secondary" size={footerButtonSize} onClick={onCancel}>
								Cancel
							</Button>
						)}

						<SubmitButton
							size={footerButtonSize}
							submissionStatus={formState.submissionStatus}
							onClick={formState.onSubmitWrapper(onSubmit)}
						>
							Next Section
						</SubmitButton>
					</Row>
				</EnrollmentApplicationModules.NavigationFooter>
			</Main.Footer>
		</Main>
	);
}

type PersonalityFormProps = {
	basicFormValues: EnrollmentApplicationStudentFormValues.EnrollmentApplicationCreateBasic;
	initialFormValues: EnrollmentApplicationStudentFormValues.EnrollmentApplicationCreatePersonality;
	onBack: (formValues: EnrollmentApplicationStudentFormValues.EnrollmentApplicationCreatePersonality) => void;
	onSubmit: FormSubmitFunction<EnrollmentApplicationStudentFormValues.EnrollmentApplicationCreatePersonality>;
	onSuccess: () => void;
};

function PersonalityForm({ basicFormValues, initialFormValues, onSubmit, onBack, onSuccess }: PersonalityFormProps) {
	const { formState } = EnrollmentApplicationModules.StudentForm.usePersonalityCreateForm({
		onSuccess,
		initialFormValues,
	});

	const footerButtonSize = EnrollmentApplicationModules.NavigationFooter.useResponsiveButtonSize();

	return (
		<Main>
			<Main.Body>
				<Column align="center">
					<Section className="main-content-section">
						<Section.Header>
							<Row justify="spaced-start" align="center">
								<EnrollmentApplicationStudentIcon image={basicFormValues.image} />
								<Heading.H2 noMargin>
									Let's Get to Know {EnrollmentApplicationModules.formatStudentNameShort(basicFormValues)}!
								</Heading.H2>
							</Row>
						</Section.Header>
						<Section.Body>
							<Row justify="center">
								<Column
									justify="spaced-start"
									className={styles["form-section__column"] + " " + styles["personality-fields"]}
								>
									<EnrollmentApplicationStudentForm.PersonalityFields formState={formState} />
								</Column>
							</Row>
						</Section.Body>
					</Section>
				</Column>
			</Main.Body>
			<Main.Footer>
				<EnrollmentApplicationModules.NavigationFooter>
					<Row justify="space-between" align="center">
						<Button variant="secondary" size={footerButtonSize} onClick={() => onBack(formState.formValues)}>
							Back
						</Button>
						<SubmitButton
							size={footerButtonSize}
							submissionStatus={formState.submissionStatus}
							onClick={formState.onSubmitWrapper(onSubmit)}
						>
							Next Section
						</SubmitButton>
					</Row>
				</EnrollmentApplicationModules.NavigationFooter>
			</Main.Footer>
		</Main>
	);
}

type InterviewFormProps = {
	basicFormValues: EnrollmentApplicationStudentFormValues.EnrollmentApplicationCreateBasic;
	initialFormValues: EnrollmentApplicationStudentFormValues.EnrollmentApplicationCreateInterview; // TODO
	onBack: (formValues: EnrollmentApplicationStudentFormValues.EnrollmentApplicationCreateInterview) => void;
	onSubmit: FormSubmitFunction<EnrollmentApplicationStudentFormValues.EnrollmentApplicationCreateInterview>;
	onSuccess: () => void;
};

function InterviewForm({ basicFormValues, initialFormValues, onBack, onSubmit, onSuccess }: InterviewFormProps) {
	const { formState } = EnrollmentApplicationModules.StudentForm.useInterviewCreateForm({
		onSuccess,
		initialFormValues,
	});

	const footerButtonSize = EnrollmentApplicationModules.NavigationFooter.useResponsiveButtonSize();

	return (
		<Main>
			<Main.Body>
				<Column align="center">
					<Section className="main-content-section">
						<Section.Header>
							<Heading.H2 noMargin>
								Let's Meet {EnrollmentApplicationModules.formatStudentNameShort(basicFormValues)}!
							</Heading.H2>
						</Section.Header>
						<Section.Body>
							<Column justify="spaced-start" align="center">
								<div>
									<Paragraph>
										All students are asked to schedule an appointment for a "Meet & Greet". This is an opportunity for
										your child to tour the school and the school to know your child.
									</Paragraph>

									<List>
										<List.Item>
											Early Years students will be interviewed by the school principal and will include "talk and play"
										</List.Item>
										<List.Item>Primary-aged students will be will participate in a quick and easy interview</List.Item>
									</List>

									<Paragraph>
										The purpose of these assessments is to help determine your child's academic and English language
										proficiency. This is NOT an entrance exam, and there is no reason for you or your child to have any
										stress!
									</Paragraph>

									<Paragraph>
										Since {EnrollmentApplicationModules.formatStudentNameShort(basicFormValues)} is applying for{" "}
										{EnrollmentApplicationStudentFormat.Fields.gradeLevelApplyingFor(
											basicFormValues.gradeLevelApplyingFor,
										)}
										, the meeting will be held at:
									</Paragraph>

									<CampusInformation
										gradeLevel={basicFormValues.gradeLevelApplyingFor}
										style={{ margin: "var(--paragraph---margin)" }}
									/>

									<Paragraph>
										Families that are not in Athens can contact us to arrange a Zoom interview at +30 211 419 9994 or
										admission@veritaschool.gr
									</Paragraph>
								</div>

								<EnrollmentApplicationStudentForm.InterviewFields
									formState={formState}
									gradeLevelApplyingFor={basicFormValues.gradeLevelApplyingFor}
								/>

								<OtherStudentMeetingTimes />
							</Column>
						</Section.Body>
					</Section>
				</Column>
			</Main.Body>
			<Main.Footer>
				<EnrollmentApplicationModules.NavigationFooter>
					<Row justify="space-between" align="center">
						<Button variant="secondary" size={footerButtonSize} onClick={() => onBack(formState.formValues)}>
							Back
						</Button>
						<SubmitButton
							size={footerButtonSize}
							submissionStatus={formState.submissionStatus}
							onClick={formState.onSubmitWrapper(onSubmit)}
						>
							Continue
						</SubmitButton>
					</Row>
				</EnrollmentApplicationModules.NavigationFooter>
			</Main.Footer>
		</Main>
	);
}

type OtherStudentMeetingTimesProps = {};

function OtherStudentMeetingTimes(_: OtherStudentMeetingTimesProps) {
	const {
		formValues: { students: otherStudents },
	} = EnrollmentApplicationModules.useEnrollmentApplicationFormContext();

	if (otherStudents.length === 0) {
		return null;
	}

	let headingText = "Your other student's interview is scheduled for";
	if (otherStudents.length > 1) {
		headingText = "Your other students' interviews are scheduled for";
	}

	return (
		<Column style={{ marginTop: "1rem" }}>
			<span>{headingText}:</span>
			<List>
				{otherStudents.map((student) => (
					<List.Item key={student.formIdentifier}>
						{EnrollmentApplicationModules.formatStudentNameShort(student)} at{" "}
						{formatDateTime(student.interviewMeetingTime ?? new Date(), "dddd, D MMM [at] HH:mm")} at{" "}
						{campusNameFromGradeLevel(student.gradeLevelApplyingFor)}.
					</List.Item>
				))}
			</List>
		</Column>
	);
}

function campusNameFromGradeLevel(
	gradeLevel: EnrollmentApplicationStudentFormValues.EnrollmentApplicationCreate["gradeLevelApplyingFor"],
) {
	switch (gradeLevel) {
		case EnrollmentApplicationStudentGradeLevelApplyingFor.Nursery:
		case EnrollmentApplicationStudentGradeLevelApplyingFor.Reception:
			return "Verita Early Years Campus";
		default:
			return "Verita Primary School Campus";
	}
}
