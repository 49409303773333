import { newPageInfo } from "@hex-insights/router";
import { rootPageInfo } from "../../root.pageinfo";

export const landingPageInfo = newPageInfo({
	name: "Landing",
	title: "",
	description: "Landing page.",
	exact: true,
	to: "/",
	path: "/",
	parent: rootPageInfo,
});
