import { newPageInfo } from "@hex-insights/router";
import { studentsPageInfo } from "../pageinfo";

export const studentsIndexPageInfo = newPageInfo({
	name: "Students Index Page",
	title: "",
	description: "Overview of students on the application.",
	exact: true,
	to: studentsPageInfo.to,
	path: studentsPageInfo.path,
	parent: studentsPageInfo,
});
