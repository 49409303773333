import React from "react";
import { HeadingLevel, If } from "@hex-insights/core";
import {
	anyFieldChanged,
	EditMode,
	Form,
	FormSubmitFunction,
	FormType,
	SubmissionStatus,
	submissionSuccess,
	SubmitButton,
	useFormState,
	useInternalField,
	ValidationDisplayPolicy,
} from "@hex-insights/forms";
import { EnrollmentApplicationParentForm, FormActionRow, FormColumn } from "../../../../Components";
import { EnrollmentApplicationFormValues, useEnrollmentApplicationFormContext } from "../../../Utilities";

export type ControlledCreateProps = {
	onSuccess: () => void;
	headingLevel?: HeadingLevel;
};

export function ControlledCreate({ onSuccess, headingLevel }: ControlledCreateProps) {
	const { formState, onSubmit } = useCreateForm({ onSuccess });

	const {
		formValues: { parents },
	} = useEnrollmentApplicationFormContext();

	return (
		<Form
			formState={formState}
			onSubmit={formState.onSubmitWrapper(onSubmit)}
			noNavigationPrompt
			noNotifications
			noChangesDialog
		>
			<FormColumn>
				<EnrollmentApplicationParentForm.EnrollmentApplicationCreateFormSections
					formState={formState}
					headingLevel={headingLevel}
					allParents={parents}
				/>

				<FormActionRow>
					<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
						Continue
					</SubmitButton>
				</FormActionRow>
			</FormColumn>
		</Form>
	);
}

export type UseCreateFormArgs = {
	onSuccess: () => void;
};

export function useCreateForm({ onSuccess }: UseCreateFormArgs) {
	const formState = useFormState({
		initialFormValues: EnrollmentApplicationFormValues.initialParentFormValues,
		validationDisplayPolicy: ValidationDisplayPolicy.failureOnly,
	});
	EnrollmentApplicationFormValues.useFormIdentifier(formState);

	const { setFormValues } = useEnrollmentApplicationFormContext();
	const onSubmit = React.useCallback<FormSubmitFunction<EnrollmentApplicationFormValues.ParentFormValues>>(
		async ({ formValues }) => {
			setFormValues((prev) => ({ ...prev, parents: [...prev.parents, formValues] }));
			return submissionSuccess();
		},
		[setFormValues],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	return { formState, onSubmit };
}

export type ControlledDetailProps = {
	initialFormValues: EnrollmentApplicationFormValues.ParentFormValues;
	onSuccess: () => void;
	setHasChanges?: (action: React.SetStateAction<boolean>) => void;
	headingLevel?: HeadingLevel;
};

export function ControlledDetail({ initialFormValues, onSuccess, setHasChanges, headingLevel }: ControlledDetailProps) {
	const formState = useFormState({
		initialFormValues: initialFormValues,
		validationDisplayPolicy: ValidationDisplayPolicy.failureOnly,
		formType: FormType.Update,
		editMode: EditMode.WriteOnly,
	});
	useInternalField(formState, "formIdentifier");

	const hasChanges = anyFieldChanged(formState);
	React.useEffect(() => {
		if (setHasChanges) {
			setHasChanges(hasChanges);
		}
	}, [setHasChanges, hasChanges]);

	const { setFormValues } = useEnrollmentApplicationFormContext();
	const onSubmit = React.useCallback<FormSubmitFunction<EnrollmentApplicationFormValues.ParentFormValues>>(
		async ({ formValues }) => {
			setFormValues((prev) => ({
				...prev,
				parents: prev.parents.map((e) => (e.formIdentifier === formValues.formIdentifier ? formValues : e)),
			}));
			return submissionSuccess();
		},
		[setFormValues],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	const {
		formValues: { parents },
	} = useEnrollmentApplicationFormContext();

	return (
		<Form
			formState={formState}
			onSubmit={formState.onSubmitWrapper(onSubmit)}
			noNavigationPrompt
			noNotifications
			noChangesDialog
		>
			<FormColumn>
				<EnrollmentApplicationParentForm.EnrollmentApplicationCreateFormSections
					formState={formState}
					headingLevel={headingLevel}
					allParents={parents}
				/>

				<If condition={hasChanges}>
					<FormActionRow>
						<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
							Update
						</SubmitButton>
					</FormActionRow>
				</If>
			</FormColumn>
		</Form>
	);
}
