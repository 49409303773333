import { newPageInfo } from "@hex-insights/router";
import { rootPageInfo } from "../../root.pageinfo";

export const nonEnrollmentConfirmationPageInfo = newPageInfo({
	title: "Non-Enrollment Confirmation",
	description: "Confirm that you do not plan on enrolling in the coming year.",
	exact: true,
	to: "/non-enrollment-confirmation",
	path: "/non-enrollment-confirmation",
	parent: rootPageInfo,
});
