import { newPageInfo } from "@hex-insights/router";
import { rootPageInfo } from "../../root.pageinfo";

export const reviewPageInfo = newPageInfo({
	title: "Review",
	description: "Review your application.",
	exact: true,
	to: "/review",
	path: "/review",
	parent: rootPageInfo,
});
