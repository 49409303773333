import React from "react";
import { Button, Column, Heading, If, Row, scrollToTop, Section, useSync } from "@hex-insights/core";
import { Form, SubmitButton } from "@hex-insights/forms";
import { useActivePageRegistration, useHistory } from "@hex-insights/router";
import { EnrollmentApplicationModules, EnrollmentApplicationParentForm, FormColumn } from "@hex-insights/verita.shared";
import { Main } from "../../../Components";
import { parentsIndexPageInfo } from "../IndexPage/pageinfo";
import { parentsCreatePageInfo } from "./pageinfo";

export function ParentsCreatePage() {
	useActivePageRegistration(parentsCreatePageInfo);

	const {
		formValues: { parents: otherParents },
	} = EnrollmentApplicationModules.useEnrollmentApplicationFormContext();
	const [numOtherParents, setNumOtherParents] = React.useState(otherParents.length);
	const hasOtherParents = numOtherParents > 0;

	const { push: pushHistory } = useHistory();
	const { syncID, synchronize } = useSync();
	const onSuccess = React.useCallback(() => {
		setNumOtherParents((prev) => prev + 1);
		if (!hasOtherParents) {
			synchronize();
			scrollToTop();
		} else {
			pushHistory(parentsIndexPageInfo.to);
		}
	}, [synchronize, hasOtherParents, pushHistory]);

	const onCancel = React.useCallback(() => {
		pushHistory(parentsIndexPageInfo.to);
	}, [pushHistory]);

	return (
		<ParentsCreatePageContent
			key={syncID}
			onSuccess={onSuccess}
			numOtherParents={numOtherParents}
			onCancel={onCancel}
		/>
	);
}

type ParentsCreatePageContentProps = {
	onSuccess: () => void;
	numOtherParents: number;
	onCancel: () => void;
};

function ParentsCreatePageContent({ onSuccess, numOtherParents, onCancel }: ParentsCreatePageContentProps) {
	const hasOtherParents = numOtherParents > 0;

	const { formState, onSubmit } = EnrollmentApplicationModules.ParentForm.useCreateForm({ onSuccess });

	const {
		formValues: { parents },
	} = EnrollmentApplicationModules.useEnrollmentApplicationFormContext();

	const footerButtonSize = EnrollmentApplicationModules.NavigationFooter.useResponsiveButtonSize();

	return (
		<Main>
			<Main.Body>
				<Column align="center">
					<Section className="main-content-section">
						<Section.Header>
							<Heading.H2 noMargin>
								Parent/Guardian Information {hasOtherParents && `#${numOtherParents + 1}`}
							</Heading.H2>
						</Section.Header>
						<Section.Body>
							<Column align="center">
								<Form
									formState={formState}
									onSubmit={formState.onSubmitWrapper(onSubmit)}
									noNavigationPrompt
									noNotifications
									noChangesDialog
								>
									<FormColumn>
										<EnrollmentApplicationParentForm.EnrollmentApplicationCreateFormSections
											formState={formState}
											allParents={parents}
											headingLevel={3}
										/>
									</FormColumn>
								</Form>
							</Column>
						</Section.Body>
					</Section>
				</Column>
			</Main.Body>
			<Main.Footer>
				<EnrollmentApplicationModules.NavigationFooter>
					<Row justify={hasOtherParents ? "space-between" : "flex-end"} align="center">
						<If condition={hasOtherParents}>
							<Button variant="secondary" size={footerButtonSize} onClick={onCancel}>
								Skip
							</Button>
						</If>

						<SubmitButton
							size={footerButtonSize}
							submissionStatus={formState.submissionStatus}
							onClick={formState.onSubmitWrapper(onSubmit)}
						>
							{hasOtherParents ? "Continue" : "Next Parent/Guardian"}
						</SubmitButton>
					</Row>
				</EnrollmentApplicationModules.NavigationFooter>
			</Main.Footer>
		</Main>
	);
}
