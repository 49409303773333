import React from "react";
import { Button, ButtonClickHandler, ButtonProps, ChildrenProps, ClassNameProps, StyleProps } from "@hex-insights/core";
import { useHistory } from "@hex-insights/router";

// TODO hex-core update (CSS to make a link look like a button)

export type ButtonLinkProps = { to: string } & Pick<ButtonProps, "variant" | "size"> &
	Partial<ClassNameProps & StyleProps & ChildrenProps>;

export function ButtonLink({ variant, size, to, className, style, children }: ButtonLinkProps) {
	const { push } = useHistory();

	const onClick = React.useCallback<ButtonClickHandler>(
		(event) => {
			if (event.metaKey) {
				window.open(to, "_blank")?.focus();
			} else {
				push(to);
			}
		},
		[push, to],
	);

	return (
		<Button variant={variant} size={size} onClick={onClick} className={className} style={style}>
			{children}
		</Button>
	);
}
