import { newPageInfo } from "@hex-insights/router";
import { rootPageInfo } from "../../root.pageinfo";

export const parentsPageInfo = newPageInfo({
	title: "Parents",
	description: "Add information about parents.",
	exact: false,
	to: "/parents",
	path: "/parents",
	parent: rootPageInfo,
});
