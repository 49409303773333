import React from "react";
import { Column, Conditional, Else, Heading, If, Paragraph, Row, Section } from "@hex-insights/core";
import {
	Form,
	FormState,
	FormSubmitFunction,
	submissionFailure,
	SubmissionStatus,
	submissionSuccess,
	SubmitButton,
	useConditionalField,
	ValidationDisplayPolicy,
} from "@hex-insights/forms";
import { MailLink, useActivePageRegistration } from "@hex-insights/router";
import { BasicTable } from "@hex-insights/tables";
import {
	BillingSetupSubmissionField,
	BillingSetupSubmissionFormState,
	BillingSetupSubmissionFormValues,
	EnrollmentApplicationModules,
	EnrollmentPricingTable,
	formatPrice,
	serverURL,
} from "@hex-insights/verita.shared";
import { Main } from "../../Components";
import {
	EnrollmentConfirmationStudentInfo,
	useEnrollmentConfirmationStudentInfo,
	useEnrollmentConfirmationToken,
} from "../../Utilities";
import { BillingSetupHeader } from "./Components";
import { billingSetupPageInfo } from "./pageinfo";

export function BillingSetupPage() {
	useActivePageRegistration(billingSetupPageInfo);

	const { tokenString, tokenErr } = useEnrollmentConfirmationToken();
	const { isLoading, studentInfo } = useEnrollmentConfirmationStudentInfo(tokenString);

	const formState = BillingSetupSubmissionFormState.useCreateFormState({
		initialFormValues: { numPayments: 4 },
		validationDisplayPolicy: ValidationDisplayPolicy.failureOnly,
	});
	const onSubmit = React.useCallback<FormSubmitFunction<BillingSetupSubmissionFormValues.Create>>(
		async (formState) => {
			const response = await fetch(serverURL("/admissions/enrollment-confirmation"), {
				method: "POST",
				headers: {
					Authorization: `Bearer ${tokenString}`,
				},
				body: JSON.stringify(formState.formValues),
			});

			if (!response.ok) {
				const responseData = await response.json();
				return submissionFailure({ _: [responseData.error] });
			}

			return submissionSuccess();
		},
		[tokenString],
	);

	return (
		<React.Fragment>
			<BillingSetupHeader />

			<Main>
				<Main.Body>
					<Column align="center">
						<Section className="main-content-section" style={{ overflow: "scroll" }}>
							<Section.Body>
								<Conditional>
									<If condition={tokenErr !== null}>
										<Paragraph>{tokenErr}</Paragraph>
									</If>
									<If condition={isLoading}>Loading...</If>
									<If condition={SubmissionStatus.isSuccess(formState.submissionStatus)}>
										<Paragraph>
											Thank you for confirming your enrollment for the 2023-2024 school year. We look forward to seeing
											you!
										</Paragraph>
									</If>
									<Else>
										{studentInfo && (
											<BillingSetupForm formState={formState} studentInfo={studentInfo} onSubmit={onSubmit} />
										)}
									</Else>
								</Conditional>
							</Section.Body>
						</Section>
					</Column>
				</Main.Body>
				<If condition={tokenErr === null && !isLoading && !SubmissionStatus.isSuccess(formState.submissionStatus)}>
					<Main.Footer>
						<EnrollmentApplicationModules.NavigationFooter>
							<Row justify="spaced-end" align="center">
								<SubmitButton
									onClick={formState.onSubmitWrapper(onSubmit)}
									submissionStatus={formState.submissionStatus}
								>
									Confirm Enrollment
								</SubmitButton>
							</Row>
						</EnrollmentApplicationModules.NavigationFooter>
					</Main.Footer>
				</If>
			</Main>
		</React.Fragment>
	);
}

const hiddenFieldNames: (keyof BillingSetupSubmissionFormValues.Create)[] = ["academicYearID", "studentID"];

const registrationFeeAmount = 900_00;

type BillingSetupFormProps = {
	formState: FormState<BillingSetupSubmissionFormValues.Create>;
	studentInfo: EnrollmentConfirmationStudentInfo;
	onSubmit: FormSubmitFunction<BillingSetupSubmissionFormValues.Create>;
};

function BillingSetupForm({ formState, studentInfo, onSubmit }: BillingSetupFormProps) {
	useConditionalField(false, formState, hiddenFieldNames);

	return (
		<Form formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<Paragraph style={{ fontSize: "1.25rem" }}>
				Let's get {studentInfo.name} set up for the coming school year!
			</Paragraph>

			<Section>
				<Section.Header>
					<Heading.H2 noMargin>Additional Services</Heading.H2>
				</Section.Header>
				<Section.Body>
					<Form.Column>
						<BillingSetupSubmissionField.IsInterestedInBusPlan formState={formState} style={{ width: "100%" }} />
						<BillingSetupSubmissionField.IsInterestedInMealPlan formState={formState} style={{ width: "100%" }} />
					</Form.Column>
				</Section.Body>
			</Section>

			<Section>
				<Section.Header>
					<Heading.H2 noMargin>Payment Schedule</Heading.H2>
				</Section.Header>
				<Section.Body>
					<Column justify="spaced-start">
						<EnrollmentPricingTable
							numPayments={formState.formValues.numPayments ?? 4}
							isInterestedInBusPlan={formState.formValues.isInterestedInBusPlan}
							isInterestedInMealPlan={formState.formValues.isInterestedInMealPlan}
							tuitionPrice={studentInfo.tuitionPrice}
						/>
						<strong style={{ display: "block", marginBottom: "1rem" }}>
							Please note that the prices displayed above are base prices and do not reflect any discounts or
							scholarships that may apply to you. A contract with exact prices will be signed at a later time.
						</strong>

						<BillingSetupSubmissionField.NumPayments formState={formState} noToolbar style={{ width: "100%" }} />

						<Section>
							<Section.Header>
								<Heading.H3 noMargin>Due at This Time</Heading.H3>
							</Section.Header>
							<Section.Body>
								<Conditional>
									<If condition={studentInfo.isNewStudent}>
										<Paragraph>
											To secure your student's spot in the class,{" "}
											<u>
												a one-time, non-refundable, new student Registration Fee of {formatPrice(registrationFeeAmount)}
											</u>{" "}
											is due.{" "}
											<strong>Payment must be received within ten (10) days of receiving your acceptance letter</strong>
											.
										</Paragraph>
									</If>
									<Else>
										<Paragraph>
											To secure your student's spot in the class,{" "}
											<u>a one-time, deductible, Re-enrollment Deposit of {formatPrice(registrationFeeAmount)}</u> is
											due. <strong>Payment must be received by May 1, 2023</strong>.
										</Paragraph>
									</Else>
								</Conditional>
							</Section.Body>
						</Section>

						<Section>
							<Section.Header>
								<Heading.H3 noMargin>Making a Payment</Heading.H3>
							</Section.Header>
							<Section.Body>
								<Paragraph>
									When making a payment, please be sure to include both your name and the child's name in the notes
									section of the payment.
								</Paragraph>

								<BasicTable
									style={
										{
											"--table__cell---font-size": "1rem",
											margin: "var(--paragraph---margin)",
										} as React.CSSProperties
									}
								>
									<BasicTable.Body>
										<BasicTable.Row>
											<BasicTable.Cell>Bank Details:</BasicTable.Cell>
											<BasicTable.Cell style={{ fontWeight: "bold" }}>Alpha Bank Greece</BasicTable.Cell>
										</BasicTable.Row>
										<BasicTable.Row>
											<BasicTable.Cell>IBAN:</BasicTable.Cell>
											<BasicTable.Cell style={{ fontWeight: "bold" }}>GR9601401540154002320017120</BasicTable.Cell>
										</BasicTable.Row>
										<BasicTable.Row>
											<BasicTable.Cell>Beneficiary:</BasicTable.Cell>
											<BasicTable.Cell style={{ fontWeight: "bold" }}>Verita International School</BasicTable.Cell>
										</BasicTable.Row>
										<BasicTable.Row>
											<BasicTable.Cell>SWIFT/BIC:</BasicTable.Cell>
											<BasicTable.Cell style={{ fontWeight: "bold" }}>CRBAGRAA</BasicTable.Cell>
										</BasicTable.Row>
									</BasicTable.Body>
								</BasicTable>

								<Paragraph>
									Should you have any questions, please feel free to contact George Laganas in our accounting office.
									His contact information is <MailLink email="george@veritaschool.gr">George@VeritaSchool.gr</MailLink>{" "}
									or +30 211 419 9994.
								</Paragraph>
							</Section.Body>
						</Section>
					</Column>
				</Section.Body>
			</Section>

			<Section>
				<Section.Header>
					<Heading.H2 noMargin>Your Information</Heading.H2>
				</Section.Header>
				<Section.Body>
					<Form.Column>
						<BillingSetupSubmissionField.AuthorName formState={formState} label="Your Name" />
						<Row justify="spaced-start" align="spaced-start" overflow="wrap">
							<BillingSetupSubmissionField.AuthorEmail formState={formState} label="Your Email Address" />
							<BillingSetupSubmissionField.AuthorPhone formState={formState} label="Your Phone Number" />
						</Row>
					</Form.Column>
				</Section.Body>
			</Section>
		</Form>
	);
}
