import {
	EnrollmentApplicationParentFormat,
	EnrollmentApplicationParentFormValues,
	EnrollmentApplicationParentRelationshipType,
} from "../../Utilities";
import * as EnrollmentApplicationFormValues from "./form";

export function formatParentName({
	firstName,
	lastName,
}: Pick<EnrollmentApplicationFormValues.ParentFormValues, "firstName" | "lastName">) {
	return `${firstName} ${lastName}`;
}

export function formatParentRelationshipType({
	relationshipType,
	relationshipTypeCustom,
}: Pick<EnrollmentApplicationFormValues.ParentFormValues, "relationshipType" | "relationshipTypeCustom">) {
	if (relationshipType === EnrollmentApplicationParentRelationshipType.Other) {
		return relationshipTypeCustom || "Other";
	}
	return EnrollmentApplicationParentFormat.Fields.relationshipType(relationshipType);
}

export function formatParentAddress({
	addressLine1,
	addressLine2,
	city,
	stateOrProvince,
	postalCode,
	country,
}: Omit<EnrollmentApplicationParentFormValues.Address, "addressSameAsID">) {
	let address = addressLine1;
	if (addressLine2 !== "") {
		address += " " + addressLine2;
	}
	if (city !== "") {
		address += ", " + city;
	}
	if (stateOrProvince !== "") {
		address += ", " + stateOrProvince;
	}
	if (postalCode !== "") {
		address += " " + postalCode;
	}
	if (country !== "") {
		address += ", " + country;
	}
	return address;
}
