import React from "react";
import { Column, Conditional, Else, If, Paragraph, Row, Section } from "@hex-insights/core";
import {
	Form,
	FormSubmitFunction,
	submissionFailure,
	SubmissionStatus,
	submissionSuccess,
	SubmitButton,
	useFormState,
	useInternalField,
	ValidationDisplayPolicy,
} from "@hex-insights/forms";
import { InternalLink, useActivePageRegistration } from "@hex-insights/router";
import { EnrollmentApplicationModules, serverURL } from "@hex-insights/verita.shared";
import { Main } from "../../Components";
import { useEnrollmentConfirmationStudentInfo, useEnrollmentConfirmationToken } from "../../Utilities";
import { BillingSetupHeader } from "../BillingSetup/Components";
import { billingSetupPageInfo } from "../BillingSetup/pageinfo";
import { nonEnrollmentConfirmationPageInfo } from "./pageinfo";

type NonEnrollmentConfirmationFormValues = {
	notReturning: boolean;
};

const initialNonEnrollmentConfirmationFormValues: NonEnrollmentConfirmationFormValues = {
	notReturning: true,
};

export function NonEnrollmentConfirmationPage() {
	useActivePageRegistration(nonEnrollmentConfirmationPageInfo);

	const { tokenString, tokenErr } = useEnrollmentConfirmationToken();
	const { isLoading } = useEnrollmentConfirmationStudentInfo(tokenString);

	const formState = useFormState({
		initialFormValues: initialNonEnrollmentConfirmationFormValues,
		validationDisplayPolicy: ValidationDisplayPolicy.failureOnly,
	});
	useInternalField(formState, "notReturning");

	const onSubmit = React.useCallback<FormSubmitFunction<NonEnrollmentConfirmationFormValues>>(
		async (formState) => {
			const response = await fetch(serverURL("/admissions/non-enrollment-confirmation"), {
				method: "POST",
				headers: {
					Authorization: `Bearer ${tokenString}`,
				},
				body: JSON.stringify(formState.formValues),
			});

			if (!response.ok) {
				const responseData = await response.json();
				return submissionFailure({ _: [responseData.error] });
			}

			return submissionSuccess();
		},
		[tokenString],
	);

	return (
		<React.Fragment>
			<BillingSetupHeader />

			<Main>
				<Main.Body>
					<Column align="center">
						<Section className="main-content-section">
							<Section.Body>
								<Paragraph style={{ fontSize: "1.1rem", textAlign: "center", lineHeight: 1.3 }}>
									We understand that deciding not to re-enroll your child can be a difficult decision, and we want to
									make sure you have all the information you need to make the best choice for your family. If there are
									specific concerns or challenges that you and your child have experienced during your time here, we
									would be happy to discuss them with you and explore potential solutions so you can stay with us.
								</Paragraph>
								<Paragraph
									style={{
										fontSize: "1.1rem",
										textAlign: "center",
										lineHeight: 1.3,
										marginBottom: "2.5rem",
									}}
								>
									Should you chose not to re-enroll, we respect your decision and want to ensure a smooth transition for
									your child. Please know you can contact our Admin team for anything you might need.
								</Paragraph>
								<Conditional>
									<If condition={SubmissionStatus.isSuccess(formState.submissionStatus)}>
										<Paragraph style={{ fontSize: "1.1rem", fontWeight: "bold", textAlign: "center" }}>
											Your decision has been confirmed. You may close this window now.
										</Paragraph>
									</If>
									<Else>
										<Form formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
											{/* for notifications */}
										</Form>
										<Paragraph style={{ fontSize: "1.1rem", fontWeight: "bold", textAlign: "center" }}>
											If you are certain you will not be returning for the upcoming year, please confirm below.
										</Paragraph>
										<Paragraph style={{ fontSize: "0.9rem", textAlign: "center" }}>
											If you are here by mistake,{" "}
											<InternalLink to={billingSetupPageInfo.to + `?token=${tokenString}`}>
												click here to confirm your enrollment instead
											</InternalLink>
											.
										</Paragraph>
									</Else>
								</Conditional>
							</Section.Body>
						</Section>
					</Column>
				</Main.Body>
				<If condition={tokenErr === null && !isLoading && !SubmissionStatus.isSuccess(formState.submissionStatus)}>
					<Main.Footer>
						<EnrollmentApplicationModules.NavigationFooter>
							<Row justify="spaced-end" align="center">
								<SubmitButton
									onClick={formState.onSubmitWrapper(onSubmit)}
									submissionStatus={formState.submissionStatus}
								>
									Confirm Decision
								</SubmitButton>
							</Row>
						</EnrollmentApplicationModules.NavigationFooter>
					</Main.Footer>
				</If>
			</Main>
		</React.Fragment>
	);
}
