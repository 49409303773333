import React from "react";
import { PersonIcon, PersonIconProps } from "../PersonIcon";

export type EnrollmentApplicationStudentIconProps = {
	image: File | null;
} & Pick<PersonIconProps, "imageSize" | "className" | "style" | "imageContainerClassName" | "imageContainerStyle">;

export function EnrollmentApplicationStudentIcon({ image, ...props }: EnrollmentApplicationStudentIconProps) {
	const imageURL = React.useMemo(
		() =>
			image !== null
				? URL.createObjectURL(image)
				: "https://cdn.shopify.com/s/files/1/0559/3160/0948/products/placeholder_58dc1ecf-fe25-4422-b786-41e70540fefd_288x.jpg", // TODO placeholder
		[image],
	);

	return <PersonIcon person={{ name: "", imageURL }} {...props} />;
}
