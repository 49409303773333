import { Column, Conditional, Else, Grid, Heading, HeadingProps, If, Paragraph, Row } from "@hex-insights/core";
import { ButtonLink, EnrollmentApplicationModules } from "@hex-insights/verita.shared";
import { parentsCreatePageInfo } from "../../Pages/Parents/CreatePage/pageinfo";
import { ParentCard } from "../ParentCard";

const responsiveGridColumns = {
	0: 1,
	600: 2,
};

export function ParentsIndex() {
	const {
		formValues: { parents },
	} = EnrollmentApplicationModules.useEnrollmentApplicationFormContext();

	return (
		<Conditional>
			<If condition={parents.length === 0}>
				<Column justify="spaced-start">
					<Paragraph>You haven't added any parent/guardian information to this application yet.</Paragraph>
					<Row justify="center">
						<ButtonLink variant="primary" to={parentsCreatePageInfo.to}>
							Add Parent/Guardian
						</ButtonLink>
					</Row>
				</Column>
			</If>
			<Else>
				<Grid responsiveColumns={responsiveGridColumns} gap="1rem">
					{parents.map((e) => (
						<ParentCard key={e.formIdentifier} parent={e} />
					))}
				</Grid>
			</Else>
		</Conditional>
	);
}

ParentsIndex.Heading = function (props: Omit<HeadingProps, "children">) {
	return <Heading {...props}>Parents &amp; Guardians</Heading>;
};
