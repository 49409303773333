import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../Components";
import { StudentsCreatePage } from "./CreatePage";
import { studentsCreatePageInfo } from "./CreatePage/pageinfo";
import { StudentsIndexPage } from "./IndexPage";
import { studentsIndexPageInfo } from "./IndexPage/pageinfo";
import { studentsPageInfo } from "./pageinfo";

export function StudentsPage() {
	useActivePageRegistration(studentsPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...studentsIndexPageInfo.routeProps}>
				<StudentsIndexPage />
			</Route>
			<Route {...studentsCreatePageInfo.routeProps}>
				<StudentsCreatePage />
			</Route>
		</PageRouteSwitch>
	);
}
