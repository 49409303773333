import { newPageInfo } from "@hex-insights/router";
import { parentsPageInfo } from "../pageinfo";

export const parentsCreatePageInfo = newPageInfo({
	title: "New Parent",
	description: "Add a parent to the application.",
	exact: true,
	to: parentsPageInfo.to + "/new",
	path: parentsPageInfo.path + "/new",
	parent: parentsPageInfo,
});
