import { Column, Conditional, Else, Heading, If, Row, Section } from "@hex-insights/core";
import { useActivePageRegistration } from "@hex-insights/router";
import { ButtonLink, EnrollmentApplicationModules } from "@hex-insights/verita.shared";
import { Main, ParentsIndex, StudentsIndex } from "../../Components";
import { parentsCreatePageInfo } from "../Parents/CreatePage/pageinfo";
import { studentsCreatePageInfo } from "../Students/CreatePage/pageinfo";
import { submissionPageInfo } from "../Submission/pageinfo";
import { reviewPageInfo } from "./pageinfo";

export function ReviewPage() {
	useActivePageRegistration(reviewPageInfo);

	const {
		formValues: { students, parents },
	} = EnrollmentApplicationModules.useEnrollmentApplicationFormContext();
	const hasStudent = students.length > 0;
	const hasParent = parents.length > 0;

	const footerButtonSize = EnrollmentApplicationModules.NavigationFooter.useResponsiveButtonSize();

	return (
		<Main>
			<Main.Body>
				<Column align="center">
					<Section className="main-content-section">
						<Section.Header>
							<Heading.H2 noMargin>Review</Heading.H2>
						</Section.Header>
						<Section.Body>
							<Section>
								<Section.Header>
									<StudentsIndex.Heading level={3} noMargin />
								</Section.Header>
								<Section.Body>
									<StudentsIndex />
								</Section.Body>
							</Section>
							<Section>
								<Section.Header>
									<ParentsIndex.Heading level={3} noMargin />
								</Section.Header>
								<Section.Body>
									<ParentsIndex />
								</Section.Body>
							</Section>
						</Section.Body>
					</Section>
				</Column>
			</Main.Body>
			<Main.Footer>
				<EnrollmentApplicationModules.NavigationFooter>
					<Row justify="spaced-end" align="center">
						<Conditional>
							<If condition={hasStudent && hasParent}>
								<ButtonLink variant="secondary" size={footerButtonSize} to={studentsCreatePageInfo.to}>
									Add Another Student
								</ButtonLink>
								<ButtonLink variant="secondary" size={footerButtonSize} to={parentsCreatePageInfo.to}>
									Add Another Parent/Guardian
								</ButtonLink>
								<ButtonLink variant="primary" size={footerButtonSize} to={submissionPageInfo.to}>
									Looks Good, Continue
								</ButtonLink>
							</If>
							<Else>
								<If condition={!hasStudent}>
									<ButtonLink variant="primary" size={footerButtonSize} to={studentsCreatePageInfo.to}>
										Add Student
									</ButtonLink>
								</If>
								<If condition={!hasParent}>
									<ButtonLink variant="primary" size={footerButtonSize} to={parentsCreatePageInfo.to}>
										Add Parent/Guardian
									</ButtonLink>
								</If>
							</Else>
						</Conditional>
					</Row>
				</EnrollmentApplicationModules.NavigationFooter>
			</Main.Footer>
		</Main>
	);
}
