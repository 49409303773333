import { newPageInfo } from "@hex-insights/router";
import { rootPageInfo } from "../../root.pageinfo";

export const studentsPageInfo = newPageInfo({
	title: "Students",
	description: "Add information about students.",
	exact: false,
	to: "/students",
	path: "/students",
	parent: rootPageInfo,
});
