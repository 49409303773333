import { Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../Components";
import { ParentsCreatePage } from "./CreatePage";
import { parentsCreatePageInfo } from "./CreatePage/pageinfo";
import { ParentsIndexPage } from "./IndexPage";
import { parentsIndexPageInfo } from "./IndexPage/pageinfo";
import { parentsPageInfo } from "./pageinfo";

export function ParentsPage() {
	useActivePageRegistration(parentsPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...parentsIndexPageInfo.routeProps}>
				<ParentsIndexPage />
			</Route>
			<Route {...parentsCreatePageInfo.routeProps}>
				<ParentsCreatePage />
			</Route>
		</PageRouteSwitch>
	);
}
