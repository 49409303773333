import React from "react";
import { Navigation, Runners } from "@hex-insights/app-modules";
import { Column, Heading, Row } from "@hex-insights/core";
import { NavLink } from "@hex-insights/router";
import { EnrollmentApplicationModules, StaticURLs } from "@hex-insights/verita.shared";
import { parentsCreatePageInfo } from "../../Pages/Parents/CreatePage/pageinfo";
import { parentsIndexPageInfo } from "../../Pages/Parents/IndexPage/pageinfo";
import { reviewPageInfo } from "../../Pages/Review/pageinfo";
import { studentsCreatePageInfo } from "../../Pages/Students/CreatePage/pageinfo";
import { studentsIndexPageInfo } from "../../Pages/Students/IndexPage/pageinfo";
import { submissionPageInfo } from "../../Pages/Submission/pageinfo";
import styles from "./styles.module.css";

export function Header() {
	return (
		<React.Fragment>
			<Navigation.SkipToContentLink />
			<Runners.Header Bar={HeaderBar} className={`${styles["header"]} no-print`} />
		</React.Fragment>
	);
}

const studentsPagePaths = new Set<string>([studentsIndexPageInfo.to, studentsCreatePageInfo.to]);
const parentsPagePaths = new Set<string>([parentsIndexPageInfo.to, parentsCreatePageInfo.to]);
const reviewPagePaths = new Set<string>([reviewPageInfo.to, submissionPageInfo.to]);

function HeaderBar() {
	const {
		formValues: { students, parents },
	} = EnrollmentApplicationModules.useEnrollmentApplicationFormContext();
	const hasStudents = students.length > 0;
	const hasParents = parents.length > 0;

	return (
		<Column justify="spaced-start" align="center" verticalSpacing="1rem" className={styles["header__bar"]}>
			<Logo />

			<Heading.H1 style={{ textAlign: "center", margin: "0 0 0.75rem" }}>
				2023-2024 Early Years &amp; Primary School Application
			</Heading.H1>

			<nav>
				<Row justify="spaced-start">
					<NavLink
						to={hasStudents ? studentsIndexPageInfo.to : studentsCreatePageInfo.to}
						isActive={(_, { pathname }) => studentsPagePaths.has(pathname)}
						activeClassName={styles["nav-link--location-active"]}
						className={styles["nav-link"]}
					>
						<Column justify="spaced-start" verticalSpacing="0.25rem" align="center">
							<div className={styles["nav-link__bar"]}></div>
							<span className={styles["nav-link__text"]}>Student</span>
						</Column>
					</NavLink>
					<NavLink
						to={hasParents ? parentsIndexPageInfo.to : parentsCreatePageInfo.to}
						isActive={(_, { pathname }) => parentsPagePaths.has(pathname)}
						activeClassName={styles["nav-link--location-active"]}
						className={styles["nav-link"]}
					>
						<Column justify="spaced-start" verticalSpacing="0.25rem" align="center">
							<div className={styles["nav-link__bar"]}></div>
							<span className={styles["nav-link__text"]}>Parents/Guardians</span>
						</Column>
					</NavLink>
					<NavLink
						to={reviewPageInfo.to}
						isActive={(_, { pathname }) => reviewPagePaths.has(pathname)}
						activeClassName={styles["nav-link--location-active"]}
						className={styles["nav-link"]}
					>
						<Column justify="spaced-start" verticalSpacing="0.25rem" align="center">
							<div className={styles["nav-link__bar"]}></div>
							<span className={styles["nav-link__text"]}>Review &amp; Submit</span>
						</Column>
					</NavLink>
				</Row>
			</nav>
		</Column>
	);
}

export function Logo() {
	return (
		<div className={styles["logo-container"]}>
			<img src={StaticURLs.Images.veritaWingsLogoAndWordmark} alt="Verita International School" />
		</div>
	);
}
