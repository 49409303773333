import React from "react";
import { asString, isString } from "@hex-insights/core";
import * as JWT from "@hex-insights/jwt";
import { useQueryStateValuesOnMount } from "@hex-insights/router";
import { mainServerAppName, projectName, serverURL } from "@hex-insights/verita.shared";

const enrollmentConfirmationTokenType = "admissions.enrollment-confirmation";

export class EnrollmentConfirmationTokenClaims extends JWT.StandardClaims {
	tokenType: string = "";
	studentID: string = "";
	enrollmentApplicationID: string = "";

	expectedIssuer: string = `${projectName}: ${mainServerAppName}`;

	setValues(obj: Record<string, any>) {
		super.setValues(obj);
		this.tokenType = obj.hasOwnProperty("tokenType") && isString(obj.tokenType) ? obj.tokenType : "";
		this.studentID = obj.hasOwnProperty("studentID") && isString(obj.studentID) ? obj.studentID : "";
		this.enrollmentApplicationID =
			obj.hasOwnProperty("enrollmentApplicationID") && isString(obj.enrollmentApplicationID)
				? obj.enrollmentApplicationID
				: "";
	}

	verify() {
		const err = super.verify();
		if (err !== null) {
			return err;
		}

		if (!this.verifyTokenType()) {
			return JWT.newValidationError("invalid token type");
		}

		if (!this.verifyIssuer(this.expectedIssuer, true)) {
			return JWT.newValidationError("invalid issuer");
		}

		return null;
	}

	verifyTokenType() {
		return this.tokenType === enrollmentConfirmationTokenType;
	}
}

const generalTokenError = "Something's wrong with this link.";
const expiredTokenError = "This link is expired.";

const filterTokenQueryType = {
	token: asString,
};

export function useEnrollmentConfirmationToken() {
	const { token: tokenString } = useQueryStateValuesOnMount(filterTokenQueryType, { token: "" }, { remove: false });

	const { token, err: tokenErr } = React.useMemo(() => {
		if (!tokenString) {
			return { token: null, err: generalTokenError };
		}
		const claims = new EnrollmentConfirmationTokenClaims();
		const parseResult = JWT.parseWithoutVerification(tokenString, claims);
		if (parseResult.err !== null) {
			return { token: null, err: generalTokenError };
		}
		const verificationErr = parseResult.token.claims.verify();
		if (verificationErr?.message === "token is expired") {
			return { token: parseResult.token, err: expiredTokenError };
		} else if (verificationErr !== null) {
			return { token: parseResult.token, err: generalTokenError };
		}

		return { token: parseResult.token, err: null };
	}, [tokenString]);

	return { token, tokenString, tokenErr };
}

export type EnrollmentConfirmationStudentInfo = {
	name: string;
	gradeLevel: string;
	isNewStudent: boolean;
	tuitionPrice: number;
};

export function useEnrollmentConfirmationStudentInfo(tokenString: string) {
	const [isLoading, setIsLoading] = React.useState(true);
	const [studentInfo, setStudentInfo] = React.useState<EnrollmentConfirmationStudentInfo | null>(null);

	React.useEffect(() => {
		if (tokenString === "") {
			return;
		}

		(async () => {
			setIsLoading(true);

			// don't use serverRequest because don't want credentials: "include"
			const response = await fetch(serverURL("/admissions/student"), {
				headers: { Authorization: `Bearer ${tokenString}` },
			});
			const responseData = await response.json();
			setStudentInfo(responseData.data);

			setIsLoading(false);
		})();
	}, [tokenString]);

	return { isLoading, studentInfo };
}
