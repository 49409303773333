import { newPageInfo } from "@hex-insights/router";
import { rootPageInfo } from "../../root.pageinfo";

export const billingSetupPageInfo = newPageInfo({
	title: "Billing Setup",
	description: "Setup up your billing preferences and confirm your enrollment.",
	exact: true,
	to: "/billing-setup",
	path: "/billing-setup",
	parent: rootPageInfo,
});
