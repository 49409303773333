import React from "react";
import {
	Button,
	ClassNameProps,
	Column,
	Heading,
	makeClassName,
	Modal,
	ModalProps,
	RequiredKeys,
	Row,
	StyleProps,
} from "@hex-insights/core";
import {
	EnrollmentApplicationModules,
	EnrollmentApplicationStudentFormat,
	EnrollmentApplicationStudentIcon,
} from "@hex-insights/verita.shared";
import styles from "./styles.module.css";

export type StudentCardProps = {
	student: EnrollmentApplicationModules.EnrollmentApplicationFormValues.StudentFormValues;
} & Partial<ClassNameProps & StyleProps>;

export function StudentCard({ student, className, style }: StudentCardProps) {
	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	return (
		<React.Fragment>
			<Column
				justify="spaced-start"
				align="center"
				className={makeClassName(styles["student-card"], className)}
				style={style}
			>
				<EnrollmentApplicationStudentIcon image={student.image} imageSize="5rem" />

				<span className={styles["student-card__name"]}>{EnrollmentApplicationModules.formatStudentName(student)}</span>

				<Row justify="space-between" style={{ width: "100%" }}>
					<span className={styles["student-card__stat"]}>{EnrollmentApplicationStudentFormat.gender(student)}</span>
					<span className={styles["student-card__stat"]}>{EnrollmentApplicationStudentFormat.age(student)} old</span>
					<span className={styles["student-card__stat"]}>
						{EnrollmentApplicationStudentFormat.Fields.gradeLevelApplyingFor(student.gradeLevelApplyingFor)}
					</span>
				</Row>

				<Button variant="link" size="small" onClick={toggleIsModalOpen} className={styles["student-card__edit-button"]}>
					Edit Student
				</Button>
			</Column>

			<Modal.If condition={isModalOpen}>
				<StudentModal student={student} onClose={toggleIsModalOpen} />
			</Modal.If>
		</React.Fragment>
	);
}

type StudentModalProps = {
	student: EnrollmentApplicationModules.EnrollmentApplicationFormValues.StudentFormValues;
} & RequiredKeys<Pick<ModalProps, "ifRef" | "onClose">, "onClose">;

function StudentModal({ student, ifRef, onClose }: StudentModalProps) {
	const [hasChanges, setHasChanges] = React.useState(false);

	return (
		<Modal ifRef={ifRef} onClose={onClose} confirmOnClose={hasChanges} className={styles["student-modal"]}>
			<Modal.Header>
				<Heading level={2} noMargin>
					{EnrollmentApplicationModules.formatStudentName(student)}
				</Heading>
			</Modal.Header>
			<Modal.Body>
				<EnrollmentApplicationModules.StudentForm.ControlledDetail
					initialFormValues={student}
					onSuccess={onClose}
					setHasChanges={setHasChanges}
				/>
			</Modal.Body>
		</Modal>
	);
}
