import { newPageInfo } from "@hex-insights/router";
import { rootPageInfo } from "../../root.pageinfo";

export const submissionPageInfo = newPageInfo({
	title: "Submit",
	description: "Confirm your submission.",
	exact: true,
	to: "/submit",
	path: "/submit",
	parent: rootPageInfo,
});
