import React from "react";
import { Navigation, Runners } from "@hex-insights/app-modules";
import { Column, Heading } from "@hex-insights/core";
import { StaticURLs } from "@hex-insights/verita.shared";
import styles from "../../../../Components/Header/styles.module.css";

export function BillingSetupHeader() {
	React.useEffect(() => {
		document.body.classList.add("body--with-simple-header");
		return () => document.body.classList.remove("body--with-simple-header");
	}, []);

	return (
		<React.Fragment>
			<Navigation.SkipToContentLink />
			<Runners.Header Bar={BillingSetupHeaderBar} className={`${styles["header"]} no-print`} />
		</React.Fragment>
	);
}

function BillingSetupHeaderBar() {
	return (
		<Column justify="spaced-start" align="center" verticalSpacing="1rem" className={styles["header__bar"]}>
			<div className={styles["logo-container"]}>
				<img src={StaticURLs.Images.veritaWingsLogoAndWordmark} alt="Verita International School" />
			</div>

			<Heading.H1 style={{ textAlign: "center", margin: "0 0 0.75rem" }}>2023-2024 Enrollment Confirmation</Heading.H1>
		</Column>
	);
}
