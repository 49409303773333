import { Column, Conditional, Else, If, Row, Section } from "@hex-insights/core";
import { useActivePageRegistration } from "@hex-insights/router";
import { ButtonLink, EnrollmentApplicationModules } from "@hex-insights/verita.shared";
import { Main, StudentsIndex } from "../../../Components";
import { parentsCreatePageInfo } from "../../Parents/CreatePage/pageinfo";
import { reviewPageInfo } from "../../Review/pageinfo";
import { studentsCreatePageInfo } from "../CreatePage/pageinfo";
import { studentsIndexPageInfo } from "./pageinfo";

export function StudentsIndexPage() {
	useActivePageRegistration(studentsIndexPageInfo);

	const {
		formValues: { students, parents },
	} = EnrollmentApplicationModules.useEnrollmentApplicationFormContext();
	const hasStudents = students.length > 0;
	const hasParents = parents.length > 0;

	const footerButtonSize = EnrollmentApplicationModules.NavigationFooter.useResponsiveButtonSize();

	return (
		<Main>
			<Main.Body>
				<Column align="center">
					<Section className="main-content-section">
						<Section.Header>
							<StudentsIndex.Heading level={2} noMargin />
						</Section.Header>
						<Section.Body>
							<StudentsIndex />
						</Section.Body>
					</Section>
				</Column>
			</Main.Body>
			<Main.Footer>
				<EnrollmentApplicationModules.NavigationFooter>
					<Row justify="spaced-end" align="center">
						<Conditional>
							<If condition={hasStudents}>
								<ButtonLink variant="secondary" size={footerButtonSize} to={studentsCreatePageInfo.to}>
									Add Another Student
								</ButtonLink>
								<Conditional>
									<If condition={hasParents}>
										<ButtonLink variant="primary" size={footerButtonSize} to={reviewPageInfo.to}>
											Continue to Review
										</ButtonLink>
									</If>
									<Else>
										<ButtonLink variant="primary" size={footerButtonSize} to={parentsCreatePageInfo.to}>
											Continue to Parent/Guardian Information
										</ButtonLink>
									</Else>
								</Conditional>
							</If>
							<Else>
								<ButtonLink variant="primary" size={footerButtonSize} to={studentsCreatePageInfo.to}>
									Add Student
								</ButtonLink>
							</Else>
						</Conditional>
					</Row>
				</EnrollmentApplicationModules.NavigationFooter>
			</Main.Footer>
		</Main>
	);
}
