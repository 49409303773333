import React from "react";
import {
	Button,
	ClassNameProps,
	Column,
	Grid,
	Heading,
	Icon,
	If,
	makeClassName,
	Modal,
	ModalProps,
	RequiredKeys,
	Row,
	StyleProps,
} from "@hex-insights/core";
import { EnrollmentApplicationModules } from "@hex-insights/verita.shared";
import styles from "./styles.module.css";

const gridResponsiveColumns = {
	0: 2,
	600: 1,
	1000: 2,
};

export type ParentCardProps = {
	parent: EnrollmentApplicationModules.EnrollmentApplicationFormValues.ParentFormValues;
} & Partial<ClassNameProps & StyleProps>;

export function ParentCard({ parent, className, style }: ParentCardProps) {
	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	return (
		<React.Fragment>
			<Column
				justify="spaced-start"
				align="center"
				className={makeClassName(styles["parent-card"], className)}
				style={style}
			>
				<span className={styles["parent-card__name"]}>{EnrollmentApplicationModules.formatParentName(parent)}</span>

				<span className={styles["parent-card__stat"]}>
					{EnrollmentApplicationModules.formatParentRelationshipType(parent)}
				</span>

				<Grid
					responsiveColumns={gridResponsiveColumns}
					gap="1rem"
					className={styles["parent-card__stat-grid"]}
					style={{ width: "100%" }}
				>
					<If condition={parent.isPrimaryContact}>
						<Row
							justify="spaced-start"
							align="center"
							horizontalSpacing="0.25rem"
							className={styles["parent-card__stat"]}
						>
							<Icon.Star size="0.9rem" className={styles["parent-card__stat__icon"]} /> Primary Contact
						</Row>
					</If>
					<If condition={parent.isFinancialContact}>
						<Row
							justify="spaced-start"
							align="center"
							horizontalSpacing="0.25rem"
							className={styles["parent-card__stat"]}
						>
							<Icon.DollarSign size="0.9rem" className={styles["parent-card__stat__icon"]} /> Financial Contact
						</Row>
					</If>
					<If condition={parent.isEmergencyContact}>
						<Row
							justify="spaced-start"
							align="center"
							horizontalSpacing="0.25rem"
							className={styles["parent-card__stat"]}
						>
							<Icon.AlertTriangle size="0.9rem" className={styles["parent-card__stat__icon"]} /> Emergency Contact
						</Row>
					</If>
					<If condition={parent.livesWithStudent}>
						<Row
							justify="spaced-start"
							align="center"
							horizontalSpacing="0.25rem"
							className={styles["parent-card__stat"]}
						>
							<Icon.Home size="0.9rem" className={styles["parent-card__stat__icon"]} /> Lives with Student
						</Row>
					</If>
					<If condition={parent.isAuthorizedForPickup}>
						<Row
							justify="spaced-start"
							align="center"
							horizontalSpacing="0.25rem"
							className={styles["parent-card__stat"]}
						>
							<Icon.Truck size="0.9rem" className={styles["parent-card__stat__icon"]} /> Authorized for Pickup
						</Row>
					</If>
				</Grid>

				<Button variant="link" size="small" onClick={toggleIsModalOpen} className={styles["parent-card__edit-button"]}>
					Edit Parent
				</Button>
			</Column>

			<Modal.If condition={isModalOpen}>
				<ParentModal parent={parent} onClose={toggleIsModalOpen} />
			</Modal.If>
		</React.Fragment>
	);
}

type ParentModalProps = {
	parent: EnrollmentApplicationModules.EnrollmentApplicationFormValues.ParentFormValues;
} & RequiredKeys<Pick<ModalProps, "ifRef" | "onClose">, "onClose">;

function ParentModal({ parent, ifRef, onClose }: ParentModalProps) {
	const [hasChanges, setHasChanges] = React.useState(false);

	return (
		<Modal ifRef={ifRef} onClose={onClose} confirmOnClose={hasChanges} className={styles["parent-modal"]}>
			<Modal.Header>
				<Heading level={2} noMargin>
					{EnrollmentApplicationModules.formatParentName(parent)}
				</Heading>
			</Modal.Header>
			<Modal.Body>
				<EnrollmentApplicationModules.ParentForm.ControlledDetail
					initialFormValues={parent}
					onSuccess={onClose}
					setHasChanges={setHasChanges}
				/>
			</Modal.Body>
		</Modal>
	);
}
