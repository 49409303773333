import { ChildrenProps, ClassNameProps, makeClassName, StyleProps } from "@hex-insights/core";
import { useScrollReadyOnMount } from "@hex-insights/router";
import styles from "./styles.module.css";

export type MainProps = {
	/** HTML `id` of the element. */
	id?: string;
} & Partial<ClassNameProps & StyleProps & ChildrenProps>;

/**
 * Renders an HTML `<main>` element with a default `id` property.
 */
export function Main({ id = "main", className, style, children }: MainProps) {
	useScrollReadyOnMount();

	return (
		<main id={id} className={makeClassName(styles["main"], "scroll-target", className)} style={style}>
			{children}
		</main>
	);
}

export type MainBodyProps = Partial<ClassNameProps & StyleProps & ChildrenProps>;

Main.Body = function ({ className, style, children }: MainBodyProps) {
	return (
		<div className={makeClassName(styles["main__body"], className)} style={style}>
			{children}
		</div>
	);
};

export type MainFooterProps = Partial<ClassNameProps & StyleProps & ChildrenProps>;

Main.Footer = function ({ className, style, children }: MainFooterProps) {
	return (
		<div className={makeClassName(styles["main__footer"], className)} style={style}>
			{children}
		</div>
	);
};
